import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { FormCheckbox, injectToolbarData, Title } from 'isotope-client'
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import {
	Actions,
	Button,
	Error,
	getFilArianeExamenType,
	Input,
	InputRadio,
	isEmailOk,
	MODE_PASSAGE,
	EXAMEN_SPECIFIQUE,
	Select,
	TYPE_EXAMEN,
	injectFormSnackbar
} from '@oceane/ui'
import * as selectors from '../services/examensTypesSelectors'
import { GENERIQUE_TYPE_EXAMEN_FCL, INS_ETAT_EXAMEN_TYPE } from '../../../../config/enums'
import { push } from 'connected-react-router'
import {
	activerExamenType,
	desactiverExamenType,
	exploiterExamenType,
	saveExamenType,
	supprimerExamenType
} from '../services/examensTypesActions'
import moment from 'moment'

const validate = (values) => {
	const errors = {}
	if (!values.libelleCourt) {
		errors.libelleCourt = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.libelleLong) {
		errors.libelleLong = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.libelleLongAnglais) {
		errors.libelleLongAnglais = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.modePassage) {
		errors.modePassage = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.dureeJourOuvreSession) {
		errors.dureeJourOuvreSession = { id: 'global.erreurs.obligatoire' }
	}
	if (isNaN(values.dureeJourOuvreSession)) {
		errors.dureeJourOuvreSession = { id: 'global.erreurs.numeric' }
	}
	if (!values.dureeMoisValiditeDiplome) {
		errors.dureeMoisValiditeDiplome = { id: 'global.erreurs.obligatoire' }
	}
	if (isNaN(values.dureeMoisValiditeDiplome)) {
		errors.dureeMoisValiditeDiplome = { id: 'global.erreurs.numeric' }
	}
	if (!values.typeExamen) {
		errors.typeExamen = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.nbInscriptionsMax) {
		errors.nbInscriptionsMax = { id: 'global.erreurs.obligatoire' }
	}
	if (isNaN(values.nbInscriptionsMax)) {
		errors.nbInscriptionsMax = { id: 'global.erreurs.numeric' }
	}
	if (!values.nbTentativeParEpreuve) {
		errors.nbTentativeParEpreuve = { id: 'global.erreurs.obligatoire' }
	}
	if (isNaN(values.nbTentativeParEpreuve)) {
		errors.nbTentativeParEpreuve = { id: 'global.erreurs.numeric' }
	}
	if (!values.delaiMaxMois) {
		errors.delaiMaxMois = { id: 'global.erreurs.obligatoire' }
	}
	if (isNaN(values.delaiMaxMois)) {
		errors.delaiMaxMois = { id: 'global.erreurs.numeric' }
	}
	if (!values.montantForfaitaire) {
		errors.montantForfaitaire = { id: 'global.erreurs.obligatoire' }
	} else {
		const montant = isNaN(values.montantForfaitaire) ? Number(values.montantForfaitaire.replace(/,/g, '.')) : values.montantForfaitaire
		if (isNaN(montant)) {
			errors.montantForfaitaire = { id: 'global.erreurs.numeric' }
		} else if (!(montant >= 0 && montant <= 1000000)) {
			errors.montantForfaitaire = { id: 'references.examensTypes.form.erreurs.montantLimite' }
		}
	}
	if (!isEmailOk(values.boiteFonctionnelle)) {
		errors.boiteFonctionnelle = { id: 'global.erreurs.formatEmail' }
	}
	if (GENERIQUE_TYPE_EXAMEN_FCL.PRO === values.typeExamen && !values.codeImportBCE) {
		errors.codeImportBCE = { id: 'global.erreurs.obligatoire' }
	}

	if (
		values.dateDebutPassage
		&& values.dateFinPassage
		&& moment(values.dateFinPassage, 'YYYY-MM-DD').isBefore(moment(values.dateDebutPassage, 'YYYY-MM-DD'))
	) {
		errors.dateDebutPassage = { id: 'references.examensTypes.form.erreurs.dateAvantDateFin' }
		errors.dateFinPassage = { id: 'references.examensTypes.form.erreurs.dateApresDateDebut' }
	}
	if (values.messageCandidatActif) {
		if (!values.messageCandidat) {
			errors.messageCandidat = { id: 'global.erreurs.obligatoire' }
		}
		if (!values.messageCandidatAnglais) {
			errors.messageCandidatAnglais = { id: 'global.erreurs.obligatoire' }
		}
	}
	return errors
}

const OngletProprietes = (props) => {

	const {
		id,
		type,
		etat,
		typeExamen,
		intl,
		saveExamenType,
		activerExamenType,
		desactiverExamenType,
		exploiterExamenType,
		supprimerExamenType,
		goToListeExamensTypes,
		snackError,
		submitting,
		handleSubmit,
		error,
		messageCandidatActif
	} = props

	const submit = values => saveExamenType(values)
		.catch(e => {
			throw new SubmissionError(e)
		})

	const exploiter = values => exploiterExamenType(values)
		.catch(e => {
			throw new SubmissionError(e)
		})

	const activer = () => activerExamenType(id)

	const desactiver = () => desactiverExamenType(id)
		.catch(e => {
			throw new SubmissionError(e)
		})

	const supprimer = () => supprimerExamenType(id)
		.then(() => {
			goToListeExamensTypes()
		})
		.catch(e => {
			e.bodyError ? snackError(e.bodyError) : snackError()
		})

	return (
		<form>
			<Error error={error} />
			<Title value="Données générales" />
			<Grid container>
				<Grid item xs={6}>
					<Field
						name="libelleCourt"
						label="Libellé court *"
						component={Input}
						inputProps={{
							maxLength: 15
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="libelleLong"
						label="Libellé long *"
						component={Input}
						inputProps={{
							maxLength: 75
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="etat"
						label="Etat"
						component={Input}
						format={value => value && intl.formatMessage({ id: `references.examensTypes.etat.${value}` })}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="libelleLongAnglais"
						label="Libellé long anglais *"
						component={Input}
						inputProps={{
							maxLength: 75
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="modePassage"
						label="Mode de passage *"
						component={Select}
						disabled={type !== TYPE_EXAMEN.STANDARD}
					>
						{Object.values(MODE_PASSAGE).map((modePassage, index) => (
							<MenuItem
								key={`modePassage-${index}`}
								value={modePassage}
							>
								<FormattedMessage id={`references.examensTypes.modePassage.${modePassage}`} />
							</MenuItem>
						))}
					</Field>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="type"
						label="Type"
						component={Input}
						format={value => value && intl.formatMessage({ id: `references.examensTypes.type.${value}` })}
						disabled
					/>
				</Grid>

				<Grid item xs={6}>
					<Field
						name="examenSpecifique"
						label="Examen spécifique"
						component={Select}
						disabled={etat !== INS_ETAT_EXAMEN_TYPE.CREE}
					>
						<MenuItem value=""></MenuItem>
						{Object.values(EXAMEN_SPECIFIQUE).map((examenSpecifique, index) => (
							<MenuItem
								key={`examenSpecifique-${index}`}
								value={examenSpecifique}
							>
								<FormattedMessage id={`references.examensTypes.examenSpecifique.${examenSpecifique}`} />
							</MenuItem>
						))}
					</Field>
				</Grid>

				{type !== TYPE_EXAMEN.PRATIQUE && <React.Fragment>
					<Grid item xs={6}>
						<Field
							name="dureeJourOuvreSession"
							label="Durée session (en jours) *"
							component={Input}
							inputProps={{
								maxLength: 2
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							name="bilingue"
							label="Bilingue"
							component={FormCheckbox}
							disabled={etat !== INS_ETAT_EXAMEN_TYPE.CREE}
						/>
					</Grid>
				</React.Fragment>}
				<Grid item xs={6}>
					<Field
						name="dureeMoisValiditeDiplome"
						label="Durée validité diplôme (en mois) *"
						component={Input}
						inputProps={{
							maxLength: 4
						}}
					/>
				</Grid>
				{type !== TYPE_EXAMEN.PRATIQUE && <Grid item xs={6}>
					<Field
						name="uniquementHelicoptere"
						label="Uniquement hélicoptère"
						component={FormCheckbox}
						disabled={etat !== INS_ETAT_EXAMEN_TYPE.CREE}
					/>
				</Grid>}
				<Grid item xs={6}>
					<Field
						name="typeExamen"
						label="Type d'examen *"
						component={Select}
						disabled={etat !== INS_ETAT_EXAMEN_TYPE.CREE || type === TYPE_EXAMEN.PRATIQUE}
					>
						{Object.values(GENERIQUE_TYPE_EXAMEN_FCL).map((typeExamenFCL, index) => (
							<MenuItem
								key={`typeExamenFCL-${index}`}
								value={typeExamenFCL}
							>
								<FormattedMessage id={`references.examensTypes.typeExamenFCL.${typeExamenFCL}`} />
							</MenuItem>
						))}
					</Field>
				</Grid>
				{type !== TYPE_EXAMEN.PRATIQUE && <Grid item xs={6}>
					<Field
						name="codeImportBCE"
						label={GENERIQUE_TYPE_EXAMEN_FCL.PRO === typeExamen ? 'Code import BCE *' : 'Code import BCE'}
						component={Input}
						inputProps={{
							maxLength: 30
						}}
					/>
				</Grid>}
				{type !== TYPE_EXAMEN.PRATIQUE && GENERIQUE_TYPE_EXAMEN_FCL.PRO === typeExamen && <Grid item xs={12}>
					<Field
						name="examenKSA"
						label="100 KSA"
						component={FormCheckbox}
					/>
				</Grid>}
				<Grid item xs={6}>
					<Field
						name="formationObligatoire"
						label="Formation obligatoire"
						component={FormCheckbox}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="creationParCandidat"
						label="Création par candidat"
						component={FormCheckbox}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="typeAlgorithme"
						label="Type algorithme"
						component={InputRadio}
						choices={[
							{
								value: 1,
								label: 'Délai et tentatives'
							},
							{
								value: 2,
								label: 'Délai glissant'
							}
						]}
						normalize={Number}
					/>
				</Grid>
				{type !== TYPE_EXAMEN.PRATIQUE && <Grid item xs={6}>
					<Field
						name="choixGrille"
						label="Choix grille"
						component={InputRadio}
						disabled={etat !== INS_ETAT_EXAMEN_TYPE.CREE}
						choices={[
							{
								value: 1,
								label: 'Grille 100'
							},
							{
								value: 2,
								label: 'Grille 144'
							}
						]}
						normalize={Number}
					/>
				</Grid>}
				{type === TYPE_EXAMEN.PRATIQUE && <React.Fragment>
					<Grid item xs={6}>
						<Field
							name="typeLettre"
							label="Type de lettre"
							component={InputRadio}
							choices={[
								{
									value: 1,
									label: 'Fiche récapitulative'
								},
								{
									value: 2,
									label: 'Affectation'
								}
							]}
							normalize={Number}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							name="boiteFonctionnelle"
							label="Boite fonctionnelle"
							component={Input}
							inputProps={{
								maxLength: 128
							}}
						/>
					</Grid>
				</React.Fragment>}
			</Grid>
			<Title value="Critères minimaux" />
			<Grid container>
				<Grid item xs={6}>
					<Field
						name="nbInscriptionsMax"
						label="Nombre d'inscriptions max *"
						component={Input}
						inputProps={{
							maxLength: 2
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="nbTentativeParEpreuve"
						label="Nombre de tentatives par épreuve *"
						component={Input}
						inputProps={{
							maxLength: 2
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="delaiMaxMois"
						label="Délai maximum (en mois) *"
						component={Input}
						inputProps={{
							maxLength: 4
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="comptabiliserAbsences"
						label="Comptabiliser les absences"
						component={FormCheckbox}
					/>
				</Grid>
			</Grid>
			<Title value="Paiement au forfait" />
			<Grid container>
				<Grid item xs={6}>
					<Field
						name="montantForfaitaire"
						label={type === TYPE_EXAMEN.PRATIQUE ? 'Redevance (en euros) *' : 'Montant (en euros) *'}
						component={Input}
						inputProps={{
							maxLength: 9
						}}
					/>
				</Grid>
			</Grid>

			<Title value="Période de passage autorisé" />
			<Grid container>
				<Grid item xs={6}>
					<Field
						id="dateDebutPassage"
						name="dateDebutPassage"
						label="Date de début"
						component={Input}
						type="date"
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						id="dateFinPassage"
						name="dateFinPassage"
						label="Date de fin"
						component={Input}
						type="date"
					/>
				</Grid>
			</Grid>

			<Title value="Message candidat" />
			<Grid container>
				<Grid item xs={12}>
					<Field
						name="messageCandidatActif"
						label="Afficher le message sur le portail candidat"
						component={FormCheckbox}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="messageCandidat"
						label={`Message candidat français${messageCandidatActif ? ' *' : ''}`}
						component={Input}
						multiline
						rows={3}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="messageCandidatAnglais"
						label={`Message candidat anglais${messageCandidatActif ? ' *' : ''}`}
						component={Input}
						multiline
						rows={3}
					/>
				</Grid>
			</Grid>

			<Actions>
				{etat === INS_ETAT_EXAMEN_TYPE.CREE && <React.Fragment>
					<Button
						color="primary"
						onClick={supprimer}
					>
						Supprimer
					</Button>
					<Button
						color="primary"
						loading={submitting}
						onClick={handleSubmit(exploiter)}
					>
						Exploiter
					</Button>
				</React.Fragment>}
				{etat === INS_ETAT_EXAMEN_TYPE.DESACTIVE && <Button
					color="primary"
					onClick={handleSubmit(activer)}
				>
					Activer
				</Button>}
				{(etat === INS_ETAT_EXAMEN_TYPE.EXPLOITABLE || etat === INS_ETAT_EXAMEN_TYPE.EXPLOITE) && <Button
					color="primary"
					onClick={handleSubmit(desactiver)}
				>
					Désactiver
				</Button>
				}
				<Button
					color="primary"
					variant="contained"
					loading={submitting}
					onClick={handleSubmit(submit)}
				>
					Enregistrer
				</Button>
			</Actions>
		</form>
	)
}

const FORM_NAME = 'modifierExamenType'

OngletProprietes.propTypes = {
	type: PropTypes.number,
	intl: intlShape,
	classes: PropTypes.object
}

const mapStateToProps = state => {
	const examenType = selectors.getExamenType(state)
	const { etat, typeExamen, messageCandidatActif } = formValueSelector(FORM_NAME)(state, 'etat', 'typeExamen', 'messageCandidatActif')
	return {
		id: examenType.id,
		libelle: examenType.libelleCourt,
		type: examenType.type,
		etat,
		typeExamen,
		messageCandidatActif,
		initialValues: {
			...examenType,
			montantForfaitaire: Number(examenType.montantForfaitaire).toFixed(2).replace(/\./g, ',')
		}
	}
}

const actions = {
	saveExamenType,
	activerExamenType,
	desactiverExamenType,
	exploiterExamenType,
	supprimerExamenType,
	goToListeExamensTypes: () => dispatch => dispatch(push('/examens-types'))
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		validate,
		enableReinitialize: true
	}),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' }),
	injectToolbarData(({ libelle, etat }) => getFilArianeExamenType(libelle, etat))
)(OngletProprietes)
