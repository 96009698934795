import {fetchFactory} from 'isotope-client'

export const getSitesEcran = (profil) => fetchFactory(`/sitesEcran?profil=${profil}`)

export const getSitesEcranForValidationDossiers = () => fetchFactory(`/sitesEcran/for-validation-dossiers`)

export const getSiteEcranById = (id) => fetchFactory(`/sitesEcran/${id}`)

export const getExamensTypeBySite = (id) => fetchFactory(`/sitesEcran/${id}/examensType`)

export const postSiteEcran = (values) => fetchFactory('/sitesEcran', {
    method: 'post',
    body: JSON.stringify(values)
})

export const getTypesSitesEcrans = () => fetchFactory('/donneesReference/typesSitesEcrans')

export const filtrerCalendrier = (values) => fetchFactory('/sitesEcran/calendrier', {
    method: 'post',
    body: JSON.stringify(values)
})

export const fermerJour = (values, jour) => fetchFactory(`/sitesEcran/fermerJournee/${jour}`, {
    method: 'post',
    body: JSON.stringify(values)
})

export const controlSitesEcrans = (values) => fetchFactory('/sitesEcran/control', {
	method: 'post',
	body: JSON.stringify(values)
})

export const activationSiteEcran = (idSiteEcran) => fetchFactory('/sitesEcran/activation', {
	method: 'post',
	body: idSiteEcran
})

export const modificationSiteEcran = (values) => fetchFactory(`/sitesEcran/${values.id}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const getListeSitesEcran = () => fetchFactory('/sitesEcran/liste')

export const modificationJourneeSiteEcran = (values) => fetchFactory(`/sitesEcran/journee`, {
    method: 'put',
    body: JSON.stringify(values)
})

export const getPlanningJournee = (id, jour) => fetchFactory(`/sitesEcran/${id}/planning/${jour}`)

export const testOPMRunningStatut = idSite => fetchFactory(`/sitesEcran/${idSite}/testOPM`)

export const reouvrirConnexions = (idSite, date) => fetchFactory(`/sitesEcran/${idSite}/reouvrir/${date}`, {
	method: 'put'
})

export const reouvrirConnexionsEpreuve = (id) => fetchFactory(`/epreuvesInscription/${id}/reouvrir`, {
	method: 'put'
})

export const getEpreuve = (id, idSite, date) => fetchFactory(`/epreuvesInscription/${id}/epreuve/${date}/${idSite}`)

export const putPanne = (id) => fetchFactory(`/epreuvesInscription/${id}/panne`, {
	method: 'put'
})

export const checkFileExist = (id, idSite, date) => fetchFactory(`/epreuvesInscription/${id}/file/${idSite}`, {
	method: 'post',
	body: JSON.stringify(date)
})

export const enregistrerDetails = (form, idSite, idEpreuve, date) => fetchFactory(`/epreuvesInscription/${idEpreuve}/file/${idSite}/${date}`, {
	method: 'put',
	body: JSON.stringify(form)
})
