import {
	CandidatPage,
	Error404Page,
	Error500Page,
	getProfilByNom,
	handleSession,
	InscriptionPratiqueIntranetPage,
	InscriptionTheoriqueIntranetPage,
	Layout,
	OceaneContext,
	OceaneLogo,
	RechercheCandidatsPage,
	refresh as refreshApi
} from '@oceane/ui'
import { IsotopeContext, Page, refreshToken as refreshTokenAction, storeLocalUser as storeLocalUserApi, ToolbarContext } from 'isotope-client'
import { logOut } from 'isotope-client/modules/login/services/loginActions'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { compose } from 'redux'
import LienManuel from '../layouts/toolbar/components/LienManuel'
import ProfileMenu from '../layouts/toolbar/components/ProfileMenu'
import SelecteurProfils from '../layouts/toolbar/components/SelecteurProfils'
import RechercheUtilisateurs from '../modules/administration/RechercheUtilisateurs'
import UtilisateurPage from '../modules/administration/UtilisateurPage'
import InformationsPage from '../modules/administration/InformationsPage'
import { QuestionExploitationPage, QuestionPage } from '../modules/banqueQuestions/questions/QuestionPage'
import RechercheQuestion from '../modules/banqueQuestions/questions/recherche/RechercheQuestion'
import CreerCandidatPage from '../modules/candidat/creer/CreerCandidatPage'
import EditionPage from '../modules/candidat/edition/EditionPage'
import ImportCandidatsPage from '../modules/candidat/import/ImportCandidatsPage'
import ValidationInscriptionsPage from '../modules/inscrirecandidats/validationInscriptions/ValidationInscriptionsPage'
import DetailExamenPage from '../modules/inscrirecandidats/detailexamen/DetailExamenPage'
import CorrectionCopiesPage from '../modules/obtenirresultats/correctionCopies/CorrectionCopiesPage'
import CorrectionEpreuvePage from '../modules/obtenirresultats/correctionCopies/CorrectionEpreuvePage'
import ModificationResultatEpreuvePage from '../modules/obtenirresultats/gestionCopies/ModificationResultatEpreuvePage'
import RechercheInscriptionPage from '../modules/obtenirresultats/gestionCopies/RechercheInscriptionPage'
import AnnexeListeAImprimer from '../modules/organiserExamens/annexes/AnnexeListeAImprimer'
import MatiereListeAImprimer from '../modules/organiserExamens/annexes/MatiereListeAImprimer'
import ListeCentresPapier from '../modules/organiserExamens/centresPapiers/ListeCentresPapier'
import EditionDiplome from '../modules/organiserExamens/diplome/EditionDiplome'
import DetailSitePhysique from '../modules/organiserExamens/localisationSites/DetailSitePhysique'
import ListeSitesPhysique from '../modules/organiserExamens/localisationSites/ListeSitesPhysique'
import CalendrierSiteEcran from '../modules/organiserExamens/siteEcran/CalendrierSiteEcran'
import DetailSiteEcran from '../modules/organiserExamens/siteEcran/DetailSiteEcran'
import ListeSitesEcrans from '../modules/organiserExamens/siteEcran/ListeSitesEcrans'
import PlanningQuotidienSiteEcran from '../modules/organiserExamens/siteEcran/PlanningQuotidienSiteEcran'
import DetailsSurveillantPage from '../modules/organiserExamens/surveillants/DetailsSurveillantPage'
import RechercheSurveillants from '../modules/organiserExamens/surveillants/RechercheSurveillants'
import AnnexeListeMatiere from '../modules/references/annexe/AnnexeListeMatiere'
import EditionAnnexe from '../modules/references/annexe/EditionAnnexe'
import ListeAnnexe from '../modules/references/annexe/ListeAnnexe'
import ComptesClient from '../modules/references/compteClient/ComptesClient'
import DetailEpreuveTypePage from '../modules/references/epreuveType/DetailEpreuveTypePage'
import ExamensTypeListe from '../modules/references/examensType/ExamensTypeListe'
import ExamenTypePage from '../modules/references/examensType/ExamenTypePage'
import DetailExaminateurEtranger from '../modules/references/examinateurEtranger/DetailExaminateurEtranger'
import ExaminateurEtranger from '../modules/references/examinateurEtranger/ExaminateurEtranger'
import FamilleListeMatiere from '../modules/references/famille/FamilleListeMatiere'
import ListeFamille from '../modules/references/famille/ListeFamille'
import ModifierFamille from '../modules/references/famille/ModifierFamille'
import ListeLignePage from '../modules/references/ligneProgramme/ligne/ListeLignePage'
import ListeMatierePage from '../modules/references/ligneProgramme/matiere/ListeMatierePage'
import ListeParagraphePage from '../modules/references/ligneProgramme/paragraphe/ListeParagraphePage'
import ListeSousParagraphePage from '../modules/references/ligneProgramme/sousParagraphe/ListeSousParagraphePage'
import ListeTopicsPage from '../modules/references/ligneProgramme/topic/ListeTopicsPage'
import OrganismeOngletPage from '../modules/references/organisme/OrganismeOngletPage'
import OrganismesPage from '../modules/references/organisme/OrganismesPage'
import ModifierPieceJustificativePage from '../modules/references/piecesJustificatives/ModifierPieceJustificativePage'
import PiecesJustificativesPage from '../modules/references/piecesJustificatives/PiecesJustificativesPage'
import { ScriptExploitationPage, ScriptPage } from '../modules/references/scripts/ScriptPage'
import ProprietesTypeFormation from '../modules/references/typesFormation/ProprietesTypeFormation'
import TypesFormationListe from '../modules/references/typesFormation/TypesFormationListe'
import ConsulterStatistiquesBanque from '../modules/resultats/statistiquesBanque/ConsulterStatistiquesBanque'
import ConsulterStatistiquesCandidats from '../modules/resultats/statistiquesCandidats/ConsulterStatistiquesCandidats'
import ConsulterStatistiquesCompetencesLinguistiques from '../modules/resultats/statistiquesCompetencesLinguistiques/ConsulterStatistiquesCompetencesLinguistiques'
import StatistiquesQuestionsPage from '../modules/resultats/statistiquesQuestions/StatistiquesQuestionsPage'
import DetailsCentrePapierPage from '../modules/organiserExamens/centresPapiers/DetailsCentrePapierPage'
import DetailSitePapier from '../modules/organiserExamens/centresPapiers/DetailSitePapier'
import CalendrierSessionPapierPage from '../modules/organiserExamens/calendrier/CalendrierSessionPapierPage'
import PaiementPage from '../modules/banqueQuestions/paiement/PaiementPage'
import { ORIGINE_PAIEMENT } from '../config/enums'
import ListeEpreuvesSujet from '../modules/elaborersujets/generersujets/ListeEpreuvesSujet'
import DetailEpreuveEcran from '../modules/organiserExamens/siteEcran/DetailEpreuveEcran'
import RechercheSessionsPapier from '../modules/organiserExamens/sessionsPapier/recherche/RechercheSessionsPapier'
import MiseEnExploitationPage from '../modules/banqueQuestions/exploitation/MiseEnExploitationPage'
import GenererSujet from '../modules/elaborersujets/generersujets/GenererSujet'
import GestionAnomaliesPage from '../modules/obtenirresultats/correctionCopies/GestionAnomaliesPage'
import TraitementAnomaliesPage from '../modules/obtenirresultats/correctionCopies/TraitementAnomaliesPage'
import SessionPapierPage from '../modules/organiserExamens/sessionsPapier/sessions/SessionPapierPage'
import SaisiePvSalleListeEpreuves from '../modules/organiserExamens/sessionsPapier/sessions/components/SaisiePvSalleListeEpreuves'
import SaisiePvSallesListeCandidats from '../modules/organiserExamens/sessionsPapier/sessions/components/SaisiePvSallesListeCandidats'
import ParametrageCentrePapier from '../modules/organiserExamens/sessionsPapier/sessions/components/ParametrageCentrePapier'
import DetailSurveillanceSession from '../modules/organiserExamens/sessionsPapier/sessions/components/DetailSurveillanceSession'
import Import100KSAPage from '../modules/banqueQuestions/import100ksa/ImportKSAPage'
import ValidationsKSAPage from '../modules/resultats/validationsksa/ValidationsKSAPage'

class App extends Component {

	constructor(props) {
		super(props)

		this.refresh()
		this.refreshInterval = setInterval(() => {
			this.refresh()
		}, 1700 * 1000)

	}

	componentDidMount() {
		const { user, getProfilStored, setProfil, cleanProfilStored } = this.props
		if (user.authenticated) {
			// Si l'utilisateur est connecté alors il possède au moins un profil
			// On récupère celui stocké ou sinon le premier de la liste
			const currentProfil = getProfilStored()
			if (currentProfil) {
				setProfil(currentProfil)
			} else {
				// Gestion de la redirection selon le profil
				setProfil(getProfilByNom(user.authorities[0].authority).id)

			}
		} else {
			// Suppression du profil stocké si le token n'est plus valide
			cleanProfilStored()
		}
	}

	refresh() {
		const { refreshAction, user } = this.props
		if (user && user.token) {
			const token = localStorage.getItem('token')
			refreshApi(token).then((json) => {
				if (json) {
					refreshAction(json.token)
					storeLocalUserApi(json)
				}
			})
		}
	}

	componentWillUnmount() {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval)
		}
	}

	async checkLogin(location) {
		const { user } = this.props
		if (!user || !user.token) {
			return Promise.resolve(false)
		}
	}

	render() {
		const { location, user, profil } = this.props
		const toolbarElements = [
			<SelecteurProfils key='selecteur' />,
			<LienManuel key='manuel' />,
			<ProfileMenu key='profil' />
		]

		if (!user.authenticated) {
			return (<Redirect to="/login" />)
		} else {
			return (
				<IsotopeContext.Provider value={{ appName: 'Oceane', logo: OceaneLogo }}>
					<ToolbarContext.Provider value={{ children: toolbarElements }}>
						<OceaneContext.Provider value={{ application: 'oceane-intranet', isPortailCandidat: false }}>
							<Page menu={'intranet_' + profil} location={location}>
								<Layout>
									<Switch>
										<Route exact path="/candidats" component={RechercheCandidatsPage} />
										<Route exact path="/candidats/nouveau" component={CreerCandidatPage} />
										<Route exact path="/candidats/:id" component={CandidatPage} />
										<Route exact path="/candidats/:id/:tab" component={CandidatPage} />
										<Route exact
											   path="/candidats/:id/examens/:idExamen/inscription-pratique/:idInscription"
											   component={InscriptionPratiqueIntranetPage} />
										<Route exact
											   path="/candidats/:id/examens/:idExamen/inscription-theorique/:idInscription"
											   component={InscriptionTheoriqueIntranetPage} />
										<Route exact
											   path="/candidats/:id/examens/:idExamen/nouvelle-inscription-pratique-:typePassage"
											   component={InscriptionPratiqueIntranetPage} />
										<Route exact
											   path="/candidats/:id/examens/:idExamen/nouvelle-inscription-:typePassage-:sessionPapier"
											   component={InscriptionTheoriqueIntranetPage} />
										<Route exact
											   path="/candidats/:id/examens/:idExamen/nouvelle-inscription-:typePassage"
											   component={InscriptionTheoriqueIntranetPage} />
										<Route exact path="/candidats/:id/examens/:idExamen/:tab" component={DetailExamenPage} />
										<Route exact path="/candidats/:id/examens/:idExamen" component={DetailExamenPage} />
										<Route exact path="/import-candidats" component={ImportCandidatsPage} />
										<Route exact path="/validation-inscriptions" component={ValidationInscriptionsPage} />
										<Route exact path="/organismes" component={OrganismesPage} />
										<Route exact path="/organismes/:idOrganisme" component={OrganismeOngletPage} />
										<Route exact path="/pieces-justificatives"
											   component={PiecesJustificativesPage} />
										<Route exact path="/pieces-justificatives/:idPj"
											   component={ModifierPieceJustificativePage} />
										<Route exact path="/types-formation" component={TypesFormationListe} />
										<Route exact path="/types-formation/:id" component={ProprietesTypeFormation} />
										<Route exact path="/comptes-clients" component={ComptesClient} />
										<Route exact path="/lignes-programme" component={ListeMatierePage} />
										<Route exact path="/ligne-programme/:idMatiere/liste-topics"
											   component={ListeTopicsPage} />
										<Route exact
											   path="/ligne-programme/:idMatiere/liste-topics/:idTopic/liste-paragraphes"
											   component={ListeParagraphePage} />
										<Route exact
											   path="/ligne-programme/:idMatiere/liste-topics/:idTopic/liste-paragraphes/:idParagraphe/liste-sous-paragraphes"
											   component={ListeSousParagraphePage} />
										<Route exact
											   path="/ligne-programme/:idMatiere/liste-topics/:idTopic/liste-paragraphes/:idParagraphe/liste-sous-paragraphes/:idSousParagraphe/liste-ligne"
											   component={ListeLignePage} />
										<Route exact path="/examinateurs-etrangers" component={ExaminateurEtranger} />
										<Route exact path="/examinateurs-etrangers/:idExaminateur"
											   component={DetailExaminateurEtranger} />
										<Route exact path="/examens-types" component={ExamensTypeListe} />
										<Route exact path="/examens-types/:id" component={ExamenTypePage} />
										<Route exact path="/examens-types/:id/:tab" component={ExamenTypePage} />
										<Route exact path="/examens-types/:id/epreuves-types/:idEpreuveType"
											   component={DetailEpreuveTypePage} />
										<Route exact
											   path="/examens-types/:id/epreuves-types/:idEpreuveType/scripts/:idScript"
											   component={ScriptPage} />
										<Route exact
											   path="/examens-types/:id/epreuves-types/:idEpreuveType/scriptsExploitation/:idScript"
											   component={ScriptExploitationPage} />
										<Route exact path="/familles" component={FamilleListeMatiere} />
										<Route exact path="/familles/:idMatiere" component={ListeFamille} />
										<Route exact path="/familles/:idMatiere/:idFamille"
											   component={ModifierFamille} />
										<Route exact path="/examens-ecran/matieres" component={MatiereListeAImprimer} />
										<Route exact path="/examens-ecran/annexes/:id"
											   component={AnnexeListeAImprimer} />
										<Route exact path="/examens-ecran/localisation-sites"
											   component={ListeSitesPhysique} />
										<Route exact path="/examens-ecran/sites-ecran" component={ListeSitesEcrans} />
										<Route exact path="/examens-ecran/sites-ecran/:id"
											   component={DetailSiteEcran} />
										<Route exact path="/examens-ecran/sites-ecran/calendrier/:id"
											   component={CalendrierSiteEcran} />
										<Route exact path="/examens/sites-ecran/:id/epreuves/:date/:idEpreuve"
											   component={DetailEpreuveEcran} />
										<Route exact path="/examens/sites-ecran/:id/planning-quotidien/:date"
											   component={PlanningQuotidienSiteEcran} />
										<Route exact path="/examens-ecran/localisation-sites/:id"
											   component={DetailSitePhysique} />
										<Route exact path="/annexes" component={AnnexeListeMatiere} />
										<Route exact path="/annexes/:idMatiere" component={ListeAnnexe} />
										<Route exact path="/annexes/:idMatiere/nouvelle-annexe"
											   component={EditionAnnexe} />
										<Route exact path="/annexes/:idMatiere/:idAnnexe" component={EditionAnnexe} />
										<Route exact path="/editions" component={EditionPage} />
										<Route exact path="/admin/gestion-utilisateurs"
										       component={RechercheUtilisateurs} />
										<Route exact path="/admin/gestion-informations"
										       component={InformationsPage} />
										<Route exact path="/stats-questions" component={StatistiquesQuestionsPage} />
										<Route exact path="/admin/gestion-utilisateurs/:idUtilisateur"
											   component={UtilisateurPage} />
										<Route exact path="/stats-banque" component={ConsulterStatistiquesBanque} />
										<Route exact path="/stats-candidats"
											   component={ConsulterStatistiquesCandidats} />
										<Route exact path="/diplomes" component={EditionDiplome} />
										<Route exact path="/stats-competences-linguistiques"
											   component={ConsulterStatistiquesCompetencesLinguistiques} />
										<Route exact path="/correction-copies" component={CorrectionCopiesPage} />
										<Route exact path="/correction-copies/epreuve/:idEpreuve"
											   component={CorrectionEpreuvePage} />
										<Route exact path="/correction-copies/epreuve/:idEpreuve"
											   component={CorrectionEpreuvePage} />
										<Route exact path="/correction-copies/epreuve/:idEpreuve/gestion-anomalies"
											   component={GestionAnomaliesPage} />
										<Route exact path="/correction-copies/epreuve/:idEpreuve/traitement-anomalies"
											   component={TraitementAnomaliesPage} />
										<Route exact path="/questions" component={RechercheQuestion} />
										<Route exact path="/surveillants" component={RechercheSurveillants} />
										<Route exact path="/centres" component={ListeCentresPapier} />
										<Route exact path="/centres/:id" component={DetailsCentrePapierPage} />
										<Route exact path="/centres/:id/:idSite" component={DetailSitePapier} />
										<Route exact path="/questions/:idQuestion" component={QuestionPage} />
										<Route exact path="/questionsExploitation/:idQuestion"
											   component={QuestionExploitationPage} />
										<Route exact path="/gestion-copies" component={RechercheInscriptionPage} />
										<Route exact path="/surveillants/:id" component={DetailsSurveillantPage} />
										<Route exact path="/gestion-copies/resultat-epreuve/:idEpreuveInscription"
											   component={ModificationResultatEpreuvePage} />
										<Route exact path="/import-100-ksa" component={Import100KSAPage} />
										<Route exact path="/calendrier" component={CalendrierSessionPapierPage} />
										<Route exact path="/paiement-francaises"
											   render={() => <PaiementPage origine={ORIGINE_PAIEMENT.FRANCAISE} />} />
										<Route exact path="/paiement-europeennes"
											   render={() => <PaiementPage origine={ORIGINE_PAIEMENT.EUROPEENNE} />} />
										<Route exact path="/sessions-papier" component={RechercheSessionsPapier} />
										<Route exact path="/sessions-papier/:idSession/:tab" component={SessionPapierPage} />
										<Route exact path="/sessions-papier/:idSession/surveillance/:idSite" component={DetailSurveillanceSession} />
										<Route exact path="/sessions-papier/:idSession/parametrage/:idCentre" component={ParametrageCentrePapier} />
										<Route exact path="/sessions-papier/:idSession/epreuves/:idSite" component={SaisiePvSalleListeEpreuves} />
										<Route exact path="/sessions-papier/:idSession/epreuves/:idSite/candidats/:idEpreuve" component={SaisiePvSallesListeCandidats} />
										<Route exact path="/exploitation" component={MiseEnExploitationPage} />
										<Route exact path="/generer-sujet/:tab" component={ListeEpreuvesSujet} />
										<Route exact path="/generer-sujet/generation/:id" component={GenererSujet} />
										<Route exact path="/validations-ksa" component={ValidationsKSAPage} />
										<Route path="/erreur*" component={Error500Page} />
										<Route path="*" component={Error404Page} />
									</Switch>
								</Layout>
							</Page>
						</OceaneContext.Provider>
					</ToolbarContext.Provider>
				</IsotopeContext.Provider>
			)
		}
	}
}

App.propTypes = {
	location: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
	refreshAction: PropTypes.func.isRequired,
	user: PropTypes.object,
	profil: PropTypes.number,
	setProfil: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	user: state.user
})

const mapDispatchToProps = (dispatch) => ({
	refreshAction: (token) => dispatch(refreshTokenAction(token)),
	logout: () => dispatch(logOut())
})


export default compose(
	handleSession,
	connect(mapStateToProps, mapDispatchToProps)
)(App)
