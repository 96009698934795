import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import { MenuItem } from '@material-ui/core'
import {
	checkAuthorities,
	EmptyResult,
	EXAMEN_SPECIFIQUE,
	injectFormSnackbar,
	Input,
	Loader,
	PROFIL_UTILISATEUR,
	SelectWithPlaceholder
} from '@oceane/ui'
import { getStyles, injectToolbarData, StaticDataTable } from 'isotope-client'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'redux'
import { getListeExamensSpecifiques } from '../../references/examensType/services/examensTypesApi'
import { InscriptionBean } from '../../../customTypings/InscriptionBean'
import { Link } from 'react-router-dom'
import { getInscriptionsAValiderExamenType } from './validationInscriptionsApi'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { ExamenTypeLightBean } from '../../../customTypings/ExamenTypeLightBean'
import { getSitesEcranForValidationDossiers } from '../../organiserExamens/siteEcran/services/siteEcranApi'
import { SiteLightBean } from '../../../customTypings/SiteLightBean'

const styles = (theme: any) => getStyles({
	filtres: {
		backgroundColor: theme.palette.containerFilter,
		padding: '11px 20px',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		columnGap: '8px',
		width: '892px',
		marginTop: '20px',
	},
	selectInput: {
		marginRight: '0px',
		width: '200px',
		height: '32px'
	},
	input: {
		marginBottom: '0px',
		width: '200px',
		marginRight: '0px'
	}
})

type ValidationInscriptionPageProps = {
	classes: any,
	snackError: any
};

/**
 * Page affichant la liste des inscriptions spécifiques CATS à valider par le gestionnaire.
 */
const ValidationInscriptionsPage = ({
	                                    classes,
	                                    snackError
                                    }: ValidationInscriptionPageProps) => {
	const [examensCATS, setExamensCATS] = React.useState<ExamenTypeLightBean[]>([])
	const [idExamenType, setIdExamenType] = React.useState<number | undefined>(undefined)
	const [sitesEcran, setSitesEcran] = React.useState<SiteLightBean[]>([])
	const [idSiteEcran, setIdSiteEcran] = React.useState<number | undefined>(undefined)
	const [nomCandidat, setNomCandidat] = React.useState<string | undefined>(undefined)
	const [prenomCandidat, setPrenomCandidat] = React.useState<string | undefined>(undefined)
	const [inscriptions, setInscriptions] = React.useState<InscriptionBean[]>([])
	const [inscriptionsLoading, setInscriptionsLoading] = React.useState<boolean>(false)

	React.useEffect(() => {
		// Dropdown examens types
		getListeExamensSpecifiques(EXAMEN_SPECIFIQUE.CATS)
			.then((examens) => {
				setExamensCATS(examens)
				if (examens.length > 0) {
					setIdExamenType(examens[0].id)
				}
			})
			.catch(() => {
				snackError(<FormattedMessage id="global.erreurs.generic" />)
			})

		// Dropdown sites écran
		getSitesEcranForValidationDossiers()
			.then((sitesEcran: SiteLightBean[]) => {
				setSitesEcran(sitesEcran)
			})
			.catch(() => {
				snackError(<FormattedMessage id="global.erreurs.generic" />)
			})

		return () => {
			setExamensCATS([])
			setSitesEcran([])
		}
	}, [])

	// Liste inscriptions
	React.useEffect(() => {
		fetchData()
	}, [idExamenType, idSiteEcran])

	const fetchData = () => {
		if (idExamenType !== undefined) {
			setInscriptions([])
			setInscriptionsLoading(true)
			getInscriptionsAValiderExamenType(idExamenType, idSiteEcran, nomCandidat, prenomCandidat)
				.then((inscriptions: InscriptionBean[]) => {
					setInscriptions(inscriptions)
				})
				.catch(() => {
					snackError(<FormattedMessage id="global.erreurs.generic" />)
				})
				.finally(() => {
					setInscriptionsLoading(false)
				})
		}
		return () => setInscriptions([])
	}

	const isMultiplesDemandes = (inscription: InscriptionBean): boolean => {
		return inscription.messageStatutDossier !== null && inscription.messageStatutDossier !== undefined && inscription.messageStatutDossier !== ''
	}

	const presstouche = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			fetchData()
		}
	}

	return (
		<React.Fragment>
			<Paper elevation={0} square className={classes.filtres}>
					<SelectWithPlaceholder
						value={idExamenType}
						displayEmpty={false}
						onChange={(event: any) => {
							setIdExamenType(event.target.value)
						}}
						id="typeExamen"
						selectClassName={classes.selectInput}
					>
						{examensCATS.map((examen: any) =>
							<MenuItem key={`examen-${examen.id}`} value={examen.id}>
								{examen.libelleCourt}
							</MenuItem>
						)}
					</SelectWithPlaceholder>

					<SelectWithPlaceholder
						value={idSiteEcran}
						placeholder="Site écran"
						onChange={(event: any) => {
							setIdSiteEcran(event.target.value)
						}}
						id="siteEcran"
						selectClassName={classes.selectInput}
					>
						{sitesEcran.map((siteEcran: SiteLightBean) =>
							<MenuItem key={`site-${siteEcran.id}`} value={siteEcran.id}>
								{siteEcran.libelle}
							</MenuItem>
						)}
					</SelectWithPlaceholder>

					<Input
						name="nomCandidat"
						placeholder="Nom candidat"
						meta={{}}
						inputProps={{ maxLength: 255 }}
						onKeyUp={presstouche}
						value={nomCandidat}
						onChange={(event: any) => {
							setNomCandidat(event.target.value)
						}}
						inputStyle={classes.input}
					/>

					<Input
						name="prenomCandidat"
						placeholder="Prénom candidat"
						meta={{}}
						inputProps={{ maxLength: 255 }}
						onKeyUp={presstouche}
						value={prenomCandidat}
						onChange={(event: any) => {
							setPrenomCandidat(event.target.value)
						}}
						inputStyle={classes.input}
					/>
			</Paper>

			{
				inscriptionsLoading ?
					<Loader />
					: (
						<StaticDataTable
							data={inscriptions}
							headers={[{
								key: 'libelleSession',
								name: 'Prochaine session',
								sortable: false,
								type: 'string'
							}, {
								key: 'numeroInscription',
								name: 'N° d\'inscription',
								type: 'string',
								sortable: false,
								render: (row: InscriptionBean) => (
									<Link to={`/candidats/${row.idCandidat}/examens/${row.idExamen}/pieces-justificatives`}>
										<strong>{row.numeroInscription}</strong>
									</Link>
								)
							}, {
								key: 'libelleSite',
								name: 'Site',
								type: 'string',
								sortable: false,
								render: (row: InscriptionBean) => row.site ? row.site.libelle : '-'
							}, {
								key: 'nomCandidat',
								name: 'Nom',
								type: 'string',
								sortable: false
							}, {
								key: 'prenomCandidat',
								name: 'Prénom',
								type: 'string',
								sortable: false
							}, {
								key: 'dossier',
								name: 'Dossier',
								type: 'string',
								sortable: false,
								render: (row: InscriptionBean) => (
									isMultiplesDemandes(row) ? 'Dossier en cours' : 'Demande initiale'
								)
							}
							]}
							noResultFragment={<EmptyResult message={<FormattedMessage id="candidats.examens.validationInscriptions.empty" />} />}
							applyRowStyle={(row: InscriptionBean) => ({
								backgroundColor: isMultiplesDemandes(row) ? '#fff6c1' : 'unset'
							})}
						/>
					)
			}
		</React.Fragment>
	)
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE),
	injectToolbarData({ title: { id: 'toolbarTitle.validation-inscriptions' } }),
	injectIntl,
	withStyles(styles),
	injectFormSnackbar(),
	injectSnackActions
)(ValidationInscriptionsPage)
